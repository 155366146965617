const proxy = require('http-proxy-middleware')
const target = 'https://www.shiatzychen.com'
// const target = 'http://ip-28-shiatzy-web-pc.coralcodes.com'

const options = {
  target,
  changeOrigin: true,
  secure: false,
  pathRewrite: {
    '^/api': '',
  },
  // headers: {
  //   host: HOSTNAME,
  // },
  logLevel: 'info',
}

module.exports = function(app) {
  app.use('/api', proxy(options))
}
