import React from 'react';
import './index.scss';
import { Link } from "react-router-dom";
import { getFooter, subscribe, getIcon } from 'api';
import { getQuery } from "utils";
import intl from "react-intl-universal";
// import Contact from '../../pages/contact/index';
import $ from 'jquery';


export default class CommonFooter extends React.Component {
  constructor() {
    super()
    this.state = {
      res: {},
      linkList: [],
      subscribeInput: '',
      errMsg: '',
      flag: false,
      // showContact: false,
      top: 0,
      iconList: []
    }
  }

  doSubscribe = () => {
    this.setState({errMsg:'', flag: false});
    // console.log(this.state.subscribeInput, '123')
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    if (!reg.test(this.state.subscribeInput)) {
      // alert(intl.get('email_err'));
      this.setState({errMsg:intl.get('email_err')})
      return
    }
    subscribe(this.state.subscribeInput).then(res => {
      if(res.code!==200) {
        this.setState({errMsg: res.message})
      } else {
        this.setState({errMsg: res.message, flag: true})
      }
    })
  };

  componentDidMount() {
    getFooter().then(res => {
      res.contentCategoryResponseList.sort((a, b) => {
        return a.id - b.id
      });
      res.indexBottomJump.navigationForPcResponses.sort((a, b) => {
        return a.id - b.id
      });
      this.setState({res})
    });

    getIcon().then(res=> {
      // console.log('iconList:',res)
      this.setState({iconList: res.data})
    })
  }

  // 跳转到contact页面之前记录当前页面的位置
  getPageSnap = () =>  {
    const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    $('html').css({"overflow": 'hidden'});
    this.setState({showContact: true, top: scrollTop});
  };

  closeContact = () => {
    this.setState({showContact: false});
    $('html').css({"overflow": 'auto'});
    document.body.scrollTop = this.state.top
  };

  render() {
    if(!this.state.res.indexBottomJump) {
      return <div className="footer-box"></div>
    }

    const {contact, basicInformation} = this.state.res;
    let visible = false;
    if(window.location.href.indexOf('store')<0){
      visible = true
    }

    const {iconList} = this.state;
    const toTop = () => {
      document.getElementById('root').scrollIntoView(true);
    };
    let currentHref = window.location.href;

    return (
      <div className="footer-box">
        <div className={`store-location ${visible? '':'hidden'}`}>
          <div className="store-location-innier">
            <div className="title">{this.state.res.searchResponse.title}</div>
            <Link to="/store" target="_blank" className="find">{this.state.res.searchResponse.subTitle}</Link>
          </div>
        </div>
        <div className="footer-inner">
        <div className="footer-left">
          <div className="top">

            <div className="part">
              <div className="title">{this.state.res.indexBottomJump.title}</div>
              <div className="list">
                {
                  this.state.res.indexBottomJump.navigationForPcResponses.map((item, index) => {
                    return (
                        <div className="item" key={`${item.id}-${index}`}>
                          {(item.id !== 223 && item.id !== 222) && (<a href={item.path}>{item.name}</a>)}
                          {item.id === 223 && (<Link to="/stories">{item.name}</Link>)}
                          {item.id === 222 && (<Link to="/runway">{item.name}</Link>)}
                        </div>
                    )
                  })
                }
              </div>
            </div>

            {
              this.state.res.contentCategoryResponseList.map((item, index) => {
                return (
                  <div className="part" key={`${item.id}-${index}`}>
                    <div className="title">{item.title}</div>
                    <div className="list">
                      {
                        item.children && item.children.map(link => {
                          return (
                            <div className="item" key={`${link.id}-${index}`}>
                              {/* {link.id===40? (<a onClick={this.getPageSnap}>{link.title}</a>):(
                                  link.link===currentHref?
                                        (<a href={link.link} onClick={toTop}>{link.title}</a>):
                                        (<a href={link.link}>{link.title}</a>)

                              )
                              } */}
                              {
                                link.link===currentHref?
                                (<a href={link.link} onClick={toTop}>{link.title}</a>):
                                (<a href={link.link}>{link.title}</a>)
                              }
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="down">
            <span className="icp"></span>
            <span className="desc" dangerouslySetInnerHTML={{ __html: basicInformation.des }}>
            </span>
          </div>
        </div>

        <div className="line"></div>

        <div className="footer-right">
          <div className="icon-list">
            {/* {this.state.res.bottomBlockResponse.reverse().map(item=>{
              const { url, alt } = getImg(item.imageUrl);
              return (
                  <a className="icon-share" href={item.link} key={item.title}>
                  </a>
              )
            })} */}
            <a className="icon-share icon-fb" target="_blank" href="https://www.facebook.com/shiatzychen.tw">
              <svg id="facebook" viewBox="0 0 32 32">
                <path d="M24,6.6h-4.6c-0.5,0-1.1,0.7-1.1,1.7v3.3H24v4.7h-5.7v14.1h-5.4V16.3H8v-4.7h4.9V8.8c0-4,2.8-7.2,6.5-7.2H24V6.6L24,6.6z"></path>
              </svg>
            </a>
            <a className="icon-share" target="_blank" href="https://lihi.cc/7JOn5">
            <svg id="line" data-name="line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            
              <path class="cls-1" d="M14.29,10.86h0c-1.68,1.93-5.43,4.28-6.29,4.64-.85.36-.73-.23-.69-.43.02-.12.11-.68.11-.68.03-.2.05-.52-.03-.72-.09-.22-.44-.34-.7-.39-3.84-.51-6.69-3.2-6.69-6.41C0,3.29,3.59.38,8,.38s8,2.91,8,6.49c0,1.43-.56,2.72-1.71,3.99ZM13.49,8.78v-.57c0-.08-.07-.15-.15-.15h-1.53v-.59h1.53c.08,0,.15-.07.15-.15v-.57c0-.08-.07-.15-.15-.15h-1.53v-.59h1.53c.08,0,.15-.07.15-.15v-.57c0-.08-.07-.15-.15-.15h-2.25c-.08,0-.15.07-.15.15h0v3.49h0c0,.09.07.16.15.16h2.25c.08,0,.15-.07.15-.15ZM5.02,8.94c.08,0,.15-.07.15-.15v-.57c0-.08-.07-.15-.15-.15h-1.53v-2.77c0-.08-.07-.15-.15-.15h-.57c-.08,0-.15.07-.15.15v3.49h0c0,.09.07.16.15.16h2.25ZM6.38,5.14h-.57c-.08,0-.15.07-.15.15v3.49c0,.08.07.15.15.15h.57c.08,0,.15-.07.15-.15v-3.49c0-.08-.07-.15-.15-.15ZM10.24,5.14h-.57c-.08,0-.15.07-.15.15v2.07l-1.6-2.16s0-.01-.01-.02c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0h-.56c-.08,0-.15.07-.15.15v3.49c0,.08.07.15.15.15h.57c.08,0,.15-.07.15-.15v-2.07l1.6,2.16s.02.03.04.04c0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.01,0,0,0,0,0,0,0,.01,0,.03,0,.04,0h.56c.08,0,.15-.07.15-.15v-3.49c0-.08-.07-.15-.15-.15Z"/>
            </svg>
                    </a>
            <a className="icon-share icon-inst" target="_blank" href="https://www.instagram.com/shiatzychen/">
              <svg id="instagram" viewBox="0 0 32 32">
              <path d="M2.3,26.2V5.8c0-1,0.3-1.8,1-2.5s1.5-1,2.5-1h20.4c1,0,1.8,0.3,2.5,1c0.7,0.7,1,1.5,1,2.5v20.4c0,1-0.3,1.8-1,2.5
                c-0.7,0.7-1.5,1-2.5,1H5.8c-1,0-1.8-0.3-2.5-1S2.3,27.2,2.3,26.2z M5.3,25.5c0,0.3,0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3h19.1
                c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.5,0.3-0.8V13.9h-2.4c0.2,0.8,0.4,1.5,0.4,2.3c0,1.5-0.4,2.9-1.1,4.2c-0.8,1.3-1.8,2.3-3.1,3
                s-2.7,1.1-4.3,1.1c-2.3,0-4.4-0.8-6-2.4c-1.7-1.6-2.5-3.6-2.5-5.8c0-0.8,0.1-1.6,0.4-2.3H5.3L5.3,25.5L5.3,25.5z M10.5,15.9
                c0,1.5,0.5,2.7,1.6,3.8s2.4,1.6,3.9,1.6c1.5,0,2.8-0.5,3.9-1.6c1.1-1,1.6-2.3,1.6-3.8c0-1.5-0.5-2.7-1.6-3.8s-2.4-1.6-3.9-1.6
                c-1.5,0-2.8,0.5-3.9,1.6S10.5,14.5,10.5,15.9z M21,9.5c0,0.3,0.1,0.6,0.4,0.9s0.5,0.4,0.9,0.4h3.1c0.3,0,0.6-0.1,0.9-0.4
                c0.2-0.2,0.4-0.5,0.4-0.9V6.6c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.4-0.9-0.4h-3.1c-0.3,0-0.6,0.1-0.9,0.4S21,6.2,21,6.6V9.5z"></path>
              </svg>
            </a>
          
            <a className="icon-share icon-ytb" target="_blank" href="https://www.youtube.com/user/SHIATZYCHENstyle">
              <svg id="youtube" viewBox="0 0 32 32">
              <path d="M2.3,24.6V7.4c0-1.4,0.5-2.6,1.5-3.6S6,2.3,7.4,2.3h17.1c1.4,0,2.6,0.5,3.6,1.5s1.5,2.2,1.5,3.6v17.1c0,1.4-0.5,2.6-1.5,3.6
                s-2.2,1.5-3.6,1.5H7.4c-1.4,0-2.6-0.5-3.6-1.5S2.3,26,2.3,24.6z M6.2,20.9c0,2.1,0.1,3.6,0.4,4.6c0.1,0.5,0.4,0.9,0.8,1.3
                s0.8,0.6,1.3,0.6c1.6,0.2,4.1,0.3,7.4,0.3s5.7-0.1,7.4-0.3c0.5-0.1,1-0.3,1.3-0.6c0.4-0.4,0.6-0.8,0.8-1.3c0.2-1,0.4-2.5,0.4-4.6
                c0-2.1-0.1-3.7-0.3-4.6c-0.1-0.5-0.4-1-0.8-1.3s-0.8-0.6-1.4-0.6c-1.6-0.2-4.1-0.3-7.4-0.3c-3.3,0-5.7,0.1-7.3,0.3
                c-0.5,0.1-1,0.3-1.4,0.6c-0.4,0.4-0.6,0.8-0.8,1.3C6.3,17.3,6.2,18.8,6.2,20.9z M7.6,17.6v-1.2h4.1v1.2h-1.4v7.6H9v-7.6H7.6z M9.4,4
                l0.4,1.2c0,0,0.1,0.4,0.4,1.2c0.4,1.2,0.7,2.2,0.8,2.8v3.6h1.3V9.3L13.9,4h-1.3l-0.9,3.5L10.7,4L9.4,4L9.4,4z M12,23.8v-5.2h1.2v4.8
                c0,0.3,0,0.4,0,0.5c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.5-0.2,0.8-0.6v-5h1.2v6.6h-1.2v-0.7c-0.5,0.5-0.9,0.8-1.4,0.8
                c-0.4,0-0.6-0.2-0.8-0.5C12,24.5,12,24.2,12,23.8L12,23.8z M14,10.7c0,0.7,0.1,1.2,0.4,1.6c0.3,0.5,0.8,0.7,1.4,0.7
                c0.6,0,1-0.2,1.4-0.7c0.2-0.3,0.4-0.8,0.4-1.6V8.4c0-0.7-0.1-1.2-0.4-1.6c-0.3-0.5-0.8-0.7-1.4-0.7c-0.6,0-1.1,0.2-1.4,0.7
                C14.1,7.2,14,7.7,14,8.4V10.7z M15.2,10.9V8.2c0-0.6,0.2-0.9,0.6-0.9c0.4,0,0.6,0.3,0.6,0.9v2.8c0,0.6-0.2,0.9-0.6,0.9
                C15.4,11.9,15.2,11.6,15.2,10.9z M16.4,25.1v-8.8h1.2v2.9c0.4-0.5,0.8-0.7,1.2-0.7c0.5,0,0.8,0.2,0.9,0.8c0.1,0.2,0.1,0.7,0.1,1.3
                v2.6c0,0.6,0,1.1-0.1,1.3c-0.1,0.5-0.5,0.8-0.9,0.8c-0.4,0-0.8-0.2-1.2-0.7v0.6H16.4z M17.6,23.9c0.2,0.2,0.4,0.3,0.6,0.3
                c0.3,0,0.5-0.3,0.5-0.9v-2.8c0-0.6-0.2-0.9-0.5-0.9c-0.2,0-0.4,0.1-0.6,0.3V23.9z M18.5,11.5c0,0.4,0,0.8,0.1,1
                c0.1,0.3,0.4,0.5,0.8,0.5c0.4,0,0.9-0.3,1.4-0.8v0.7h1.2V6.2h-1.2v5.1c-0.3,0.4-0.5,0.6-0.8,0.6c-0.2,0-0.3-0.1-0.3-0.3
                c0,0,0-0.2,0-0.5V6.3h-1.2C18.5,6.3,18.5,11.5,18.5,11.5z M20.8,23v-2.3c0-0.7,0.1-1.2,0.4-1.5c0.3-0.5,0.8-0.7,1.4-0.7
                s1.1,0.2,1.4,0.7c0.2,0.3,0.4,0.8,0.4,1.5v1.4H22v1.2c0,0.6,0.2,0.9,0.6,0.9c0.3,0,0.5-0.2,0.5-0.5c0,0,0-0.1,0-0.1
                c0-0.1,0-0.2,0-0.3v-0.4h1.2V23c0,0.3,0,0.6,0,0.8c0,0.3-0.1,0.5-0.3,0.7c-0.3,0.5-0.8,0.7-1.4,0.7c-0.6,0-1.1-0.2-1.4-0.7
                C20.9,24.2,20.8,23.7,20.8,23L20.8,23z M22,21.1h1.2v-0.6c0-0.6-0.2-0.9-0.6-0.9S22,19.9,22,20.5L22,21.1L22,21.1z"></path>
              </svg>
            </a>
            <a className="icon-share icon-wb" target="_blank" href="http://www.weibo.com/shiatzychen">
              <svg id="weibo" viewBox="0 0 32 32">
                <path d="M12.054 22.929q0.375-0.607 0.196-1.232t-0.804-0.893q-0.607-0.25-1.304-0.018t-1.071 0.821q-0.393 0.607-0.232 1.223t0.768 0.902 1.33 0.045 1.116-0.848zM13.732 20.768q0.143-0.232 0.063-0.473t-0.313-0.33q-0.25-0.089-0.509 0.009t-0.384 0.33q-0.304 0.554 0.232 0.804 0.25 0.089 0.518-0.009t0.393-0.33zM16.839 22.679q-0.804 1.821-2.821 2.679t-4 0.214q-1.911-0.607-2.634-2.259t0.116-3.348q0.839-1.661 2.705-2.482t3.759-0.339q1.982 0.518 2.83 2.134t0.045 3.402zM22.411 19.821q-0.161-1.714-1.589-3.036t-3.723-1.946-4.902-0.375q-3.982 0.411-6.598 2.527t-2.366 4.723q0.161 1.714 1.589 3.036t3.723 1.946 4.902 0.375q3.982-0.411 6.598-2.527t2.366-4.723zM27.911 19.893q0 1.214-0.661 2.491t-1.946 2.446-3.009 2.098-4.036 1.482-4.83 0.554-4.911-0.598-4.295-1.661-3.063-2.696-1.161-3.563q0-2.054 1.241-4.375t3.527-4.607q3.018-3.018 6.098-4.214t4.402 0.125q1.161 1.143 0.357 3.732-0.071 0.25-0.018 0.357t0.179 0.125 0.259-0.009 0.241-0.063l0.107-0.036q2.482-1.054 4.393-1.054t2.732 1.089q0.804 1.125 0 3.179-0.036 0.232-0.080 0.357t0.080 0.223 0.214 0.134 0.304 0.107q1.018 0.321 1.839 0.839t1.429 1.455 0.607 2.080zM26.589 8.75q0.75 0.839 0.973 1.938t-0.116 2.098q-0.143 0.411-0.527 0.607t-0.795 0.071q-0.411-0.143-0.607-0.527t-0.071-0.795q0.357-1.125-0.429-1.982t-1.911-0.625q-0.429 0.089-0.804-0.143t-0.446-0.661q-0.089-0.429 0.143-0.795t0.661-0.455q1.071-0.232 2.125 0.098t1.804 1.17zM29.821 5.839q1.554 1.714 2.009 3.973t-0.241 4.313q-0.161 0.482-0.607 0.714t-0.929 0.071-0.714-0.607-0.089-0.929q0.5-1.464 0.179-3.071t-1.429-2.821q-1.107-1.232-2.643-1.705t-3.089-0.152q-0.5 0.107-0.929-0.17t-0.536-0.777 0.17-0.92 0.777-0.527q2.196-0.464 4.357 0.205t3.714 2.402z"></path>
              </svg>
            </a>
          
            <a className="icon-share icon-wx" target="_blank" href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MjM5Mzg3OTYyNA==&scene=124#wechat_redirect">
              <svg id="wechat" viewBox="0 0 37 32">
                <path d="M10.357 8.232q0-0.732-0.446-1.179t-1.179-0.446q-0.768 0-1.357 0.455t-0.589 1.17q0 0.696 0.589 1.152t1.357 0.455q0.732 0 1.179-0.438t0.446-1.17zM23.625 17.286q0-0.5-0.455-0.893t-1.17-0.393q-0.482 0-0.884 0.402t-0.402 0.884q0 0.5 0.402 0.902t0.884 0.402q0.714 0 1.17-0.393t0.455-0.911zM19.411 8.232q0-0.732-0.438-1.179t-1.17-0.446q-0.768 0-1.357 0.455t-0.589 1.17q0 0.696 0.589 1.152t1.357 0.455q0.732 0 1.17-0.438t0.438-1.17zM30.75 17.286q0-0.5-0.464-0.893t-1.161-0.393q-0.482 0-0.884 0.402t-0.402 0.884q0 0.5 0.402 0.902t0.884 0.402q0.696 0 1.161-0.393t0.464-0.911zM26 10.196q-0.554-0.071-1.25-0.071-3.018 0-5.554 1.375t-3.991 3.723-1.455 5.134q0 1.393 0.411 2.714-0.625 0.054-1.214 0.054-0.464 0-0.893-0.027t-0.982-0.116-0.795-0.125-0.973-0.188-0.893-0.188l-4.518 2.268 1.286-3.893q-5.179-3.625-5.179-8.75 0-3.018 1.741-5.554t4.714-3.991 6.491-1.455q3.143 0 5.938 1.179t4.679 3.259 2.438 4.652zM36.571 20.214q0 2.089-1.223 3.991t-3.313 3.455l0.982 3.232-3.554-1.946q-2.679 0.661-3.893 0.661-3.018 0-5.554-1.259t-3.991-3.42-1.455-4.714 1.455-4.714 3.991-3.42 5.554-1.259q2.875 0 5.411 1.259t4.063 3.429 1.527 4.705z"></path>
              </svg>
            </a>
          </div>

          <div className="part font">
            <div className="title">{intl.get('SUBSCRIBE_FOR_UPDATES')}</div>
            <div className="subscribe-box">
              <input
                type="text"
                className="input"
                onChange={e => this.setState({ subscribeInput: e.target.value })}
                placeholder={intl.get('Please_enter_your_email_address')}
              />
              <span className="subs-btn" onClick={this.doSubscribe}>{intl.get('SUBSCRIBE')}</span>
              {this.state.errMsg?<div className={`validate ${this.state.flag?'right':''}`}>{this.state.errMsg}</div>:''}
            </div>
          </div>

          <div className="part small font">
            <div className="title">{intl.get('CUSTOMER_SERVICE_LINE')}</div>
            <div className="item">{intl.get('phone')}</div>
            <div className="item">({intl.get('CHINA_ONLY')})</div>
            <div className="item">{intl.get('Monday_to_Friday_9AM_to_6PM')}</div>
            <div className="item">{intl.get('email')}</div>
            <div className="item">({intl.get('All_Regions')})</div>
            {/* <div className="title">{contact.title}</div>
            <div className="item">{contact.phone}</div>
            <div className="item">{contact.phoneRemark}</div>
            <div className="item">{contact.businessHours}</div>
            <div className="item">{contact.email}</div>
            <div className="item">{contact.emailRemark}</div> */}
          </div>
        </div>
      </div>
        {/* <Contact show={this.state.showContact} close={this.closeContact}/> */}
    </div>
    )
  }
}
